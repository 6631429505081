import Box from '@mui/material/Box';
import Link from 'next/link';
import { SOCIAL_LINKS } from 'constants/nav-links';
import { useGetClientInfoQuery } from 'store/rtk/service';
import { useTheme } from '@mui/material';

const IGNORED_COUNTRIES = ['RU', 'BY'];

const Links = () => {
  return (
    <Box
      display='flex'
      alignItems='center'
      gap={{ phxs: 2, lpxs: 3 }}
      justifyContent={{ phxs: 'space-between', tblg: 'normal' }}
    >
      {SOCIAL_LINKS.map(({ icon, link, color }) => (
        <Link
          shallow
          href={link}
          as={link}
          key={link}
          passHref
          target={'_blank'}
          rel='noopener noreferrer'
        >
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            sx={{
              cursor: 'pointer',
              height: 24,
              width: 24,
              path: {
                fill: (theme) => theme.palette.grey[400]
              },
              ':hover path': {
                fill: color
              }
            }}
          >
            {icon}
          </Box>
        </Link>
      ))}
    </Box>
  );
};

const SocialLinks = ({ withBorder = false }: { withBorder?: boolean }) => {
  const theme = useTheme();
  const { data } = useGetClientInfoQuery();

  if (!data || IGNORED_COUNTRIES.includes(data?.country)) return <span />;

  if (withBorder)
    return (
      <Box
        width={1}
        p={2}
        borderRadius={theme.spacing(2)}
        border={`1px solid ${theme.palette.greyTransparency[500]}`}
      >
        <Links />
      </Box>
    );

  return <Links />;
};

export default SocialLinks;
