import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '@lib/query';
import withError from './queryLifecycle/withError';
import checkUserBan from './queryLifecycle/checkUserBan';
import { HYDRATE } from 'next-redux-wrapper';
import type { User } from 'models/types/user';

const accountTags = [
  'accountInventory',
  'accountCases',
  'accountUpgrade',
  'accountGifts',
  'inventoryAmount'
];
const casesTags = ['cases', 'case', 'freeCase'];
const depositTags = ['depositPromo'];
const fairTags = ['fairSeed'];
const freeCasesTags = ['lastFreeCase'];
const promosTags = [
  'promoCodeHistory',
  'promoCaseHistory',
  'promoActive',
  'promoCaseHistory',
  'allUserPromo',
  'referral'
];
const upgraderTags = ['upgraderInventory'];
const giveawayTags = ['giveaways', 'giveawayInfo'];
const notificationsTags = ['notifications'];
const battleTags = ['battle', 'battleInfo', 'battleInfoPrivate'];

const eventTags = [
  'user-token',
  'user-refill-list',
  'event-token-bank-top',
  'event-token-bank-user'
];

const userGiveaways = ['ugw-id', 'ugw-progress'];

export const dropskinApi = createApi({
  reducerPath: 'api',
  baseQuery,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  // Result, Query
  tagTypes: [
    'user',
    'balance',
    'deposit',
    'UNKNOWN_ERROR',
    ...accountTags,
    ...casesTags,
    ...depositTags,
    ...fairTags,
    ...freeCasesTags,
    ...promosTags,
    ...upgraderTags,
    ...giveawayTags,
    ...notificationsTags,
    ...battleTags,
    ...eventTags,
    ...userGiveaways
  ],
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => ({
        url: '/user-auth/me',
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
        await checkUserBan({ dispatch, queryFulfilled });
      },
      providesTags: ['user']
    }),
    getUserBalance: builder.query<number, void>({
      query: () => ({
        url: '/account/me',
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['balance'],
      transformResponse: (response: { balance: number }) => response.balance
    }),
    getUserTransaction: builder.query<number, void>({
      query: () => ({
        url: '/transaction-auth/me',
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['deposit']
    }),
    refetchErroredQueries: builder.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['UNKNOWN_ERROR']
    }),
    // general info about client
    getClientInfo: builder.query<ClientInfoResponse, void>({
      query: () => ({
        url: '/auth-service/auth/ip-info',
        method: 'get'
      })
    })
  })
});

export const {
  useGetUserQuery,
  useGetUserBalanceQuery,
  useGetUserTransactionQuery,
  useGetClientInfoQuery,
  util: { getRunningOperationPromises }
} = dropskinApi;
export const dropskinApiMiddleware = dropskinApi.middleware;

type ClientInfoResponse = {
  abused: boolean;
  country: string;
  data: {
    ip: string;
    rir: string;
    is_bogon: boolean;
    is_mobile: boolean;
    is_crawler: boolean;
    is_datacenter: boolean;
    is_tor: boolean;
    is_proxy: boolean;
    is_vpn: boolean;
    is_abuser: boolean;
    company: {
      name: string;
      abuser_score: string;
      domain: string;
      type: string;
      network: string;
      whois: string;
    };
    abuse: {
      name: string;
      address: string;
      email: string;
      phone: string;
    };
    asn: {
      asn: number;
      abuser_score: string;
      route: string;
      descr: string;
      country: string;
      active: boolean;
      org: string;
      domain: string;
      abuse: string;
      type: string;
      created: string;
      updated: string;
      rir: string;
      whois: string;
    };
    location: {
      is_eu_member: boolean;
      calling_code: string;
      currency_code: string;
      continent: string;
      country: string;
      country_code: string;
      state: string;
      city: string;
      latitude: number;
      longitude: number;
      zip: string;
      timezone: string;
      local_time: string;
      local_time_unix: number;
      is_dst: boolean;
    };
    elapsed_ms: number;
  };
  status: boolean;
};
